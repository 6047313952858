/*
=========================================================
COMPONENTS CE-GALLERY
individuelle Anpassungen www.optik-wuensche.de
=========================================================
*/

@include max($b-small-max) {
  .ce_gallery {
    > ul {
      display: flex;
      flex-flow: column;
      align-items: center;
      > li {
        margin-right: 0;
        &.col_last {
          margin-right: 0;
        }
      }
    }
  }
}
