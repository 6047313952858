/*
=========================================================
COMPONENTS Main Navigation
individuelle Anpassungen www.optik-wuensche.de
=========================================================
*/

.main-navigation {
  @include max($b-medium-max) {
    display: none;
  }
}
.main-navigation-mobile-open {
  @include max($b-medium-max) {
    display: block;
  }
}

.main-navigation-mobile {
  @include max($b-medium-max) {
    display: block;
  }
  &.is-enabled {
    @include max($b-medium-max) {
      display: block;
    }
  }
}
