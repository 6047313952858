/*
=========================================================
COMPONENTS Logo
individuelle Anpassungen www.optik-wuensche.de
=========================================================
*/

.logo {
  @include max($b-medium-max) {
    float: none;
    text-align: center;
    margin-left: 0;
    padding-top: 12 / 15 * 1em;
  }
  img {
    @include max($b-medium-max) {
      height: 4em;
    }
  }
}
