@charset "UTF-8";
/*
================================================================================
Stylesheet Wünsche Augenoptik (custom.css)
Autor: Maren Lange
Anpassung Theme Rocksolid Oneo
benutzt wird die library Bourbon
================================================================================
*/
/*
=========================================================
COMPONENTS CE-GALLERY
individuelle Anpassungen www.optik-wuensche.de
=========================================================
*/
@media only screen and (max-width: 599px) {
  .ce_gallery > ul {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .ce_gallery > ul > li {
    margin-right: 0;
  }
  .ce_gallery > ul > li.col_last {
    margin-right: 0;
  }
}

/*
=========================================================
COMPONENTS HEADLINE-BOX
individuelle Anpassungen www.optik-wuensche.de
=========================================================
*/
.headline-box h1,
.headline-box h2 {
  margin: 0.111em 0;
  font-size: 2.4em;
  font-weight: normal;
  letter-spacing: 0.1em;
  color: inherit;
  text-transform: uppercase;
}

@media only screen and (max-width: 519px) {
  .headline-box h1,
  .headline-box h2 {
    font-size: 1.8667em;
  }
}

.headline-box h1.-bold,
.headline-box h2.-bold {
  font-weight: bold;
}

.headline-box h1.-small,
.headline-box h2.-small {
  font-size: 1.6em;
}

.headline-box h1.-medium,
.headline-box h2.-medium {
  font-size: 2.1333em;
}

@media only screen and (max-width: 519px) {
  .headline-box h1.-medium,
  .headline-box h2.-medium {
    font-size: 1.6em;
  }
}

.headline-box h1.-large,
.headline-box h2.-large {
  font-size: 2.8em;
}

@media only screen and (max-width: 519px) {
  .headline-box h1.-large,
  .headline-box h2.-large {
    font-size: 2.1333em;
  }
}

.headline-box h1.-super-size,
.headline-box h2.-super-size {
  font-size: 3.4667em;
}

@media only screen and (max-width: 519px) {
  .headline-box h1.-super-size,
  .headline-box h2.-super-size {
    font-size: 2.4em;
  }
}

.headline-box h1 span,
.headline-box h2 span {
  font-size: 0.3889em;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.5;
  color: #98a0ab;
  text-transform: uppercase;
}

.-color-inverted.-highlight-color .headline-box h1 span, .-color-inverted.-highlight-color
.headline-box h2 span {
  color: #18555f;
}

.-color-inverted .headline-box h1 span, .-color-inverted
.headline-box h2 span {
  color: white;
}

.headline-box h1 span.-small,
.headline-box h2 span.-small {
  font-size: 0.86667rem;
}

.headline-box h1 span.-medium,
.headline-box h2 span.-medium {
  font-size: 1.06667rem;
}

@media only screen and (max-width: 519px) {
  .headline-box h1 span.-medium,
  .headline-box h2 span.-medium {
    font-size: 0.5833em;
  }
}

.headline-box h1 span.-large,
.headline-box h2 span.-large {
  font-size: 0.5em;
}

@media only screen and (max-width: 519px) {
  .headline-box h1 span.-large,
  .headline-box h2 span.-large {
    font-size: 0.5em;
  }
}

.headline-box h1 span.-super-size,
.headline-box h2 span.-super-size {
  font-size: 0.5833em;
}

@media only screen and (max-width: 519px) {
  .headline-box h1 span.-super-size,
  .headline-box h2 span.-super-size {
    font-size: 0.5em;
  }
}

/*
=========================================================
COMPONENTS Logo
individuelle Anpassungen www.optik-wuensche.de
=========================================================
*/
@media only screen and (max-width: 900px) {
  .logo {
    float: none;
    text-align: center;
    margin-left: 0;
    padding-top: 0.8em;
  }
}

@media only screen and (max-width: 900px) {
  .logo img {
    height: 4em;
  }
}

/*
=========================================================
COMPONENTS Main Navigation
individuelle Anpassungen www.optik-wuensche.de
=========================================================
*/
@media only screen and (max-width: 900px) {
  .main-navigation {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .main-navigation-mobile-open {
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  .main-navigation-mobile {
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  .main-navigation-mobile.is-enabled {
    display: block;
  }
}

/*
=========================================================
COMPONENTS Area Navigation
individuelle Anpassungen www.optik-wuensche.de
=========================================================
*/
.area-navigation a {
  color: #98a0ab;
}

.area-navigation a:after {
  background-color: #98a0ab;
}

.area-navigation a.active:after, .area-navigation a:hover:after {
  background-color: rgba(152, 160, 171, 0.25);
  border-color: #98a0ab;
}

/*
=========================================================
COMPONENTS NEWS
individuelle Anpassungen www.optik-wuensche.de
=========================================================
*/
.portfolio-detail > .back {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto 3rem;
}

@media only screen and (max-width: 1280px) {
  .portfolio-detail > .back {
    width: 93.75%;
    width: var(--page-inner-width);
    max-width: none;
    margin-right: 0;
    margin-left: 3.125%;
    margin-left: var(--page-padding-left);
  }
}
