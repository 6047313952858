/*
=========================================================
COMPONENTS HEADLINE-BOX
individuelle Anpassungen www.optik-wuensche.de
=========================================================
*/

// headline-box fuer Ueberschriften unterschiedlicher Ordnung anpassen
.headline-box {
  h1,
  h2 {
    margin: 0.111em 0;
    font-size: 2.4em;
    font-weight: normal;
    letter-spacing: 0.1em;
    color: inherit;
    text-transform: uppercase;
    @include max($b-xsmall-max) {
      font-size: 1.8667em;
    }

    &.-bold {
      font-weight: bold;
    }
    &.-small {
      font-size: 1.6em;
    }
    &.-medium {
      font-size: 2.1333em;
      @include max($b-xsmall-max) {
        font-size: 1.6em;
      }
    }
    &.-large {
      font-size: 2.8em;
      @include max($b-xsmall-max) {
        font-size: 2.1333em;
      }
    }
    &.-super-size {
      font-size: 3.4667em;
      @include max($b-xsmall-max) {
        font-size: 2.4em;
      }
    }
    span {
      // margin: 0;
      font-size: 0.3889em;
      font-weight: bold;
      letter-spacing: 0.1em;
      line-height: 1.5;
      color: $color-alternative;
      text-transform: uppercase;
      .-color-inverted.-highlight-color & {
        color: $color-accent-alternative;
      }
      .-color-inverted & {
        color: $color-inverted-alternative;
      }
      &.-small {
        font-size: 13 / 15 * 1rem;
      }
      &.-medium {
        font-size: 16 / 15 * 1rem;
        @include max($b-xsmall-max) {
          font-size: 0.5833em;
        }
      }
      &.-large {
        font-size: 0.5em;
        @include max($b-xsmall-max) {
          font-size: 0.5em;
        }
      }
      &.-super-size {
        font-size: 0.5833em;
        @include max($b-xsmall-max) {
          font-size: 0.5em;
        }
      }
    }
  }
}
