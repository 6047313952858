/*
=========================================================
COMPONENTS Area Navigation
individuelle Anpassungen www.optik-wuensche.de
=========================================================
*/

.area-navigation {
  a {
    color: $slider-control-color;
    &:after {
      background-color: $bg-color-invers-alternative;
    }
    &.active,
    &:hover {
      &:after {
        background-color: rgba($bg-color-invers-alternative, 0.25);
        border-color: $border-color-alternative;
      }
    }
  }
}
