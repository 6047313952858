@charset 'UTF-8';
/*
================================================================================
Stylesheet Wünsche Augenoptik (custom.css)
Autor: Maren Lange
Anpassung Theme Rocksolid Oneo
benutzt wird die library Bourbon
================================================================================
*/
// Libraries
@import "bourbon";

// TOOLS - eigene Funktionen und Mixins
@import "tools/mixins/media_queries";

// GENERAL - allgemeines css
@import "setting";
// @import 'general/font';

// COMPONENTS - css fuer die Komponenten der Website
@import "components/ce_gallery";
@import "components/headline_box";
@import "components/logo";
@import "components/navigation_main";
@import "components/navigation_area";
@import "components/news";
// LAYOUT
