// ==============================================
// Custom settings Oneo
// individuelle Anpassungen www.optik-wuensche.de
// ==============================================

$max-width: 1280px;
$max-width-inner: $max-width / 32 * 30;

// Breakpoints
$b-xxsmall: 320px;
$b-xxsmall-max: 399px; // Mobile Hochformat
$b-xsmall: 400px;
$b-xsmall-max: 519px;
$b-small: 520px;
$b-small-max: 599px; // Mobile
$b-medium: 600px;
$b-medium-max: 900px; //Tablet
$b-large: 901px;
$b-xlarge: 1035px;
$b-xlarge-max: 1280px; // Desktop
$b-xxlarge: 1279px;

// Farbschema
$astral: #3197a4;
$eden: #18555f;
$gray-chateau: #98a0ab;

// Color
$color-alternative: $gray-chateau; // Schriftfarbe hell
$color-accent: $astral; // Hightlight Farbe
$color-accent-alternative: $eden; // Hightlight Farbe dunkel
$color-inverted: white; // Schriftfarbe invertiert
$color-inverted-alternative: white; // Schriftfarbe hell invertiert

// Background-Color
$bg-color-accent: $astral;
$bg-color-invers-alternative: $gray-chateau;

// Border-Color
$border-color-accent: $astral;
$border-color-alternative: $gray-chateau;

// Slider
$slider-control-color: $color-alternative;
