/*
=========================================================
COMPONENTS NEWS
individuelle Anpassungen www.optik-wuensche.de
=========================================================
*/
.portfolio-detail > .back {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: $max-width-inner;
  margin: 2rem auto 3rem;
  @include max($max-width) {
    width: 30 / 32 * 100%;
    width: var(-(-page-inner-width));
    max-width: none;
    margin-right: 0;
    margin-left: 1 / 32 * 100%;
    margin-left: var(-(-page-padding-left));
  }
}
